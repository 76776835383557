import { useContext, useEffect } from 'react';
import { Icon } from '@uralhim-innovative/farmik-ui';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import { MainRoutes } from '../../routes';
import logo from '../../../shared/assets/images/logo.svg';
import { DashboardRoutes } from '../../modules/dashboard/dashboard.routes';
import { ProfileRoutes } from '../../modules/dashboard/pages/profile/routes';
import { Colors } from '../../../shared/consts/color';
import { RootStoresContext } from '../../../App';
import { NotificationPanel } from '../../../notifications/components/NotificationPanel/NotificationPanel';
import { useStore } from '../../../shared/utils/IoC';
import { ProfileStore } from '../../modules/dashboard/pages/profile/stores/ProfileStore';

import {
  Header,
  HeaderWrapper,
  Logo,
  LogoWrapper,
  NavigationMenu,
  NavigationMenuItem,
  UserActionsMenu,
  UserActionsMenuItem,
  MobileNavigationMenu,
  MobileNavigationMenuItem,
} from './style';

export const HeaderComponent = observer(() => {
  const { headerComponentStore, uiStateStore } = useContext(RootStoresContext);
  const { headerContent, options } = headerComponentStore;
  const { isMobileDevice, isWidthLessThanContent } = uiStateStore;
  const { getUserInfo, user } = useStore(ProfileStore);
  const history = useHistory();

  useEffect(() => {
    getUserInfo();
  }, []);

  if (history.location.pathname === MainRoutes.agreement) {
    return null;
  }

  return (
    <>
      <HeaderWrapper height={isMobileDevice ? options.height : '76px'}>
        <Header>
          {Boolean(headerContent) && isMobileDevice ? (
            headerContent
          ) : (
            <>
              <NavigationMenu>
                <LogoWrapper onClick={() => history.push('')}>
                  <Logo src={logo} />
                </LogoWrapper>
                <NavigationMenuItem
                  onClick={() => history.push(MainRoutes.main)}
                  data-test-id="header-primary-button"
                >
                  <Icon size="small" icon="home" />
                  <span>Главная</span>
                </NavigationMenuItem>
                <NavigationMenuItem
                  onClick={() => history.push(MainRoutes.cultures)}
                  data-test-id="header-culture-button"
                >
                  <Icon size="small" icon="catalog" />
                  <span>Культуры</span>
                </NavigationMenuItem>
                <NavigationMenuItem
                  onClick={() => history.push(MainRoutes.services)}
                  data-test-id="header-services-button"
                >
                  <Icon size="small" icon="settings" />
                  <span>Сервисы</span>
                </NavigationMenuItem>
                {/* <NavigationMenuItem*/}
                {/*  onClick={() => history.push(MainRoutes.calculator)}*/}
                {/*  data-test-id="header-services-calculator-button"*/}
                {/* >*/}
                {/*  <Icon size="small" icon="dew" />*/}
                {/*  <span>Калькулятор</span>*/}
                {/* </NavigationMenuItem>*/}
                {/* <NavigationMenuItem data-test-id="header-vacancy-button">*/}
                {/*  <Icon size="small" icon="account_info" />*/}
                {/*  <span>Вакансии</span>*/}
                {/* </NavigationMenuItem>*/}
              </NavigationMenu>
              <UserActionsMenu>
                <UserActionsMenuItem
                  data-test-id="header-addWidget-button"
                  onClick={() => history.push(ProfileRoutes.Widgets)}
                  hideOnMobile={true}
                >
                  <Icon size="large" icon="add_widget" />
                </UserActionsMenuItem>
                <UserActionsMenuItem
                  onClick={() => {
                    if (isWidthLessThanContent) {
                      window.location.href = `${window._env_.AUTH_URL}${ProfileRoutes.ExternalAgroidProfile}`;
                    } else {
                      history.push(ProfileRoutes.Widgets);
                    }
                  }}
                  data-test-id="header-useer-button"
                  noPadding={Boolean(user && user.avatar)}
                >
                  {user && user.avatar ? (
                    <img src={user.avatar.downloadUrl} alt="" />
                  ) : (
                    <Icon size="large" icon="user" />
                  )}
                </UserActionsMenuItem>
              </UserActionsMenu>
            </>
          )}
        </Header>
      </HeaderWrapper>
      <MobileNavigationMenu>
        <MobileNavigationMenuItem
          onClick={() => history.push(MainRoutes.main)}
          data-test-id="header-primary-button"
        >
          <Icon size="small" icon="home" fill={Colors.darkGray} />
          <span>Главная</span>
        </MobileNavigationMenuItem>
        <MobileNavigationMenuItem
          onClick={() => history.push(MainRoutes.cultures)}
          data-test-id="header-culture-button"
        >
          <Icon size="small" icon="catalog" />
          <span>Культуры</span>
        </MobileNavigationMenuItem>
        <MobileNavigationMenuItem
          onClick={() => history.push(MainRoutes.services)}
          data-test-id="header-services-button"
        >
          <Icon size="small" icon="settings" fill={Colors.darkGray} />
          <span>Сервисы</span>
        </MobileNavigationMenuItem>
      </MobileNavigationMenu>
    </>
  );
});
